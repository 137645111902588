import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import Slider from './ProductSlider'
import { Link } from 'react-router-dom'
import poster from '../resources-nikker/poster-nikker.jpg'
import kat from '../resources-nikker/kat.jpg'
import Navigation from './Navigation'
import katalog from '../resources-nikker/nk.pdf'
import banner from '../resources-nikker/bannerNG.jpg'


export default class About extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <Navigation />
                        <img src={banner} classsName='p-5' alt="nikker" width="100%"></img>
                        <Slider />

                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Link to='/Products'>
                            <img src={poster} width='100%' />
                            <div className='products p-2 nav-custom'>
                                
                                    Pogledajte proizvode
                                
                            </div>
                        </Link>
                    </Col>
                    <Col xs="12">
                        <a href={katalog} target="_blank">
                            <img src={kat} width='100%' alt='' />
                            <div className='products p-2 nav-custom'>
                                
                                    Pogledajte katalog
                               
                            </div>
                        </a>
                    </Col>
                </Row>
            </div>
        )
    }
}
