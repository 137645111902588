/*  id, name, img, about, group  */

export const storeProducts = [

  {
    id: 1,
    name: "SERIJA LINE",
    img: "./images/JPEG/20.jpg",
    img2: "./images/2",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "set",
    tehnicki: "./images/tehnicki/serija-line-teh.jpg",
    vName: "VERTIKALA LINE SA KORPOM",
    vSifra: "35-896",
    vDimenzije: "350x300x1900",
    vName2: "VERTIKALA LINE BEZ KORPE ",
    vSifra2: "35-897",
    vDimenzije2: "350x300x1900",
    ogName: "OGLEDALO SLIM LINE",
    ogSifra1: "35-893",
    ogDimenzije1: "610x180x1000",
    ogName2: "OGLEDALO SLIM LINE",
    ogSifra2: "35-894",
    ogDimenzije2: "710x180x1000",
    // ogSifra3: "35-877",
    // ogDimenzije3: "710x250x700",
    ormName: "ORMARIC LINE KONZOLNI I LAVABO SLIM",
    ormSifra1: "35-890",
    ormDimenzije1: "610x460x500",
    ormName2: "ORMARIC LINE KONZOLNI I LAVABO SLIM",
    ormSifra2: "35-891",
    ormDimenzije2: "710x460x500",
    // ormName3: "ORMARIC LINE KONZOLNI + LAVABO SLIM",
    // ormSifra3: "35-872",
    // ormDimenzije3: "710x460x500",
  },
  {
    id: 2,
    name: "SERIJA KARAMEL",
    img: "./images/JPEG/50.jpg",
    img2: "./images/5",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    b6: "./images/bedz/karamel.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "set",
    tehnicki: "./images/tehnicki/serija-karamel-teh.jpg",
    vName: "VERTIKALA KARAMEL SA KORPOM KONZOLNA",
    vSifra: "35-860",
    vDimenzije: "350x300x1520",
    // vName2: "VERTIKALA LINE BEZ KORPE ",
    // vSifra2: "35-897",
    // vDimenzije2: "350x300x1900",
    ogName: "OGLEDALO KARAMEL",
    ogSifra1: "35-855",
    ogDimenzije1: "510x250x700",
    ogName2: "OGLEDALO KARAMEL",
    ogSifra2: "35-856",
    ogDimenzije2: "610x250x700",
    ogName3: "OGLEDALO KARAMEL",
    ogSifra3: "35-857",
    ogDimenzije3: "710x250x700",
    ormName: "ORMARIC KARAMEL KONZOLNI + LAVABO SLIM",
    ormSifra1: "35-850",
    ormDimenzije1: "510x460x500",
    ormName2: "ORMARIC KARAMEL KONZOLNI + LAVABO SLIM",
    ormSifra2: "35-851",
    ormDimenzije2: "610x460x500",
    ormName3: "ORMARIC KARAMEL KONZOLNI + LAVABO SLIM",
    ormSifra3: "35-852",
    ormDimenzije3: "710x460x500",
  },
  {
    id: 3,
    name: "SERIJA LIGHT",
    img: "./images/JPEG/80.jpg",
    img2: "./images/3",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    b6: "./images/bedz/light.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "set",
    tehnicki: "./images/tehnicki/serija-light-teh.jpg",
    vName: "VERTIKALA LIGHT SA KORPOM KONZOLNA",
    vSifra: "35-880",
    vDimenzije: "350x300x1520",
    ogName: "OGLEDALO LIGHT",
    ogSifra1: "35-875",
    ogDimenzije1: "510x250x700",
    ogName2: "OGLEDALO LIGHT",
    ogSifra2: "35-876",
    ogDimenzije2: "610x250x700",
    ogName3: "OGLEDALO LIGHT",
    ogSifra3: "35-877",
    ogDimenzije3: "710x250x700",
    ormName: "ORMARIC LIGHT KONZOLNI",
    ormSifra1: "35-870",
    ormDimenzije1: "510x460x500",
    ormName2: "ORMARIC LIGHT KONZOLNI",
    ormSifra2: "35-871",
    ormDimenzije2: "610x460x500",
    ormName3: "ORMARIC LIGHT KONZOLNI",
    ormSifra3: "35-872",
    ormDimenzije3: "710x460x500",
  },
  {
    id: 4,
    name: "Serija Sonoma",
    img: "./images/JPEG/10.jpg",
    img2: "./images/1",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    b6: "./images/bedz/sonoma.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "set",
    tehnicki: "./images/tehnicki/serija-sonoma-teh.jpg",
    vName: "VERTIKALA SONOMA SA KORPOM KONZOLNA",
    vSifra: "35-388",
    vDimenzije: "350x300x1400",
    ogName: "OGLEDALO SONOMA",
    ogSifra1: "35-386",
    ogDimenzije1: "800x150x700",
    ormName: "ORMARIC SONOMA KONZOLNI + LAVABO TULIP",
    ormSifra1: "35-381",
    ormDimenzije1: "800x460x500",
  },
  {
    id: 5,
    name: "Set konzolni karamel + lavabo alvit",
    img: "./images/JPEG/5.jpg",
    img2: "./images/7",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    b6: "./images/bedz/karamel.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "set",
    // tehnicki: "./images/tehnicki/serija-sonoma-teh.jpg",
    ormName: "OGLEDALO GORNJI DEO KARAMEL",
    ormSifra1: "35-190",
    ormDimenzije1: "450x120x600",
    ormName2: "ORMARIC KONZOLNI KARAMEL + LAVABO ALVIT",
    ormSifra2: "35-195",
    ormDimenzije2: "450x250x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 6,
    name: "Set konzolni light + lavabo alvit",
    img: "./images/JPEG/6.jpg",
    img2: "./images/6",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    b6: "./images/bedz/light.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "set",
    // tehnicki: "./images/tehnicki/serija-sonoma-teh.jpg",
    ormName: "OGLEDALO GORNJI DEO LIGHT",
    ormSifra1: "35-191",
    ormDimenzije1: "450x120x600",
    ormName2: "ORMARIC KONZOLNI LIGHT + LAVABO ALVIT",
    ormSifra2: "35-196",
    ormDimenzije2: "450x250x500",
    ogName: 0,
    vName: 0,
  },
  // ************************ ORMARICI ***********************
  // *********************************************************

  {
    id: 20,
    name: "ORMARIC LUX + LAVABO TULIP",
    img: "./images/JPEG/140.jpg",
    img2: "./images/ormarici/14",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC LUX + LAVABO TULIP",
    ormSifra1: "35-151",
    ormDimenzije1: "550x460x850",
    ormName2: "ORMARIC LUX + LAVABO TULIP",
    ormSifra2: "35-152",
    ormDimenzije2: "650x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 21,
    name: "ORMARIC LUX + LAVABO TULIP 800",
    img: "./images/JPEG/141.jpg",
    img2: "./images/ormarici/15",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC LUX + LAVABO TULIP",
    ormSifra1: "35-153",
    ormDimenzije1: "800x460x850",
    // ormSifra2: "35-152",
    // ormDimenzije2: "650x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 22,
    name: "ORMARIC + LAVABO TULIP",
    img: "./images/JPEG/161.jpg",
    img2: "./images/ormarici/16",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC + LAVABO TULIP",
    ormSifra1: "35-402",
    ormDimenzije1: "550x460x850",
    ormName2: "ORMARIC + LAVABO TULIP",
    ormSifra2: "35-403",
    ormDimenzije2: "650x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 23,
    name: "ORMARIC + LAVABO TULIP 800",
    img: "./images/JPEG/171.jpg",
    img2: "./images/ormarici/17",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC + LAVABO TULIP 800",
    ormSifra1: "35-404",
    ormDimenzije1: "800x460x850",
    ormName2: "ORMARIC + LAVABO TULIP 1000",
    ormSifra2: "35-405",
    ormDimenzije2: "1000x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 24,
    name: "ORMARIC FIOKA + LAVABO TULIP",
    img: "./images/JPEG/181.jpg",
    img2: "./images/ormarici/18",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC FIOKA + LAVABO TULIP",
    ormSifra1: "35-406",
    ormDimenzije1: "550x460x850",
    ormName2: "ORMARIC FIOKA + LAVABO TULIP",
    ormSifra2: "35-407",
    ormDimenzije2: "650x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 25,
    name: "ORMARIC FIOKA + LAVABO TULIP 800",
    img: "./images/JPEG/191.jpg",
    img2: "./images/ormarici/19",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC FIOKA + LAVABO TULIP 800",
    ormSifra1: "35-408",
    ormDimenzije1: "800x460x850",
    ormName2: "ORMARIC FIOKA + LAVABO TULIP 1000",
    ormSifra2: "35-409",
    ormDimenzije2: "1000x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 26,
    name: "ORMARIC + LAVABO SLIM 1200",
    img: "./images/JPEG/201.jpg",
    img2: "./images/ormarici/20",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC + LAVABO SLIM 1200",
    ormSifra1: "35-207",
    ormDimenzije1: "1200x460x850",
    // ormSifra2: "35-409",
    // ormDimenzije2: "1000x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 27,
    name: "ORMARIC KONZOLNI 500 ART - A1",
    img: "./images/JPEG/231.jpg",
    img2: "./images/ormarici/23",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI 500 ART-A1",
    ormSifra1: "35-800-A1",
    ormDimenzije1: "500x500x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 28,
    name: "ORMARIC KONZOLNI 500 ART - A2",
    img: "./images/JPEG/241.jpg",
    img2: "./images/ormarici/24",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI 500 ART-A2",
    ormSifra1: "35-800-A",
    ormDimenzije1: "500x500x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 29,
    name: "ORMARIC KONZOLNI 500 ART - A3",
    img: "./images/JPEG/251.jpg",
    img2: "./images/ormarici/25",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI 500 ART-A3",
    ormSifra1: "35-800-A3",
    ormDimenzije1: "500x500x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 30,
    name: "ORMARIC KONZOLNI 500 ART - A4",
    img: "./images/JPEG/261.jpg",
    img2: "./images/ormarici/26",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI 500 ART-A4",
    ormSifra1: "35-800-A4",
    ormDimenzije1: "500x500x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 31,
    name: "ORMARIC KONZOLNI 500 ART - A5",
    img: "./images/JPEG/271.jpg",
    img2: "./images/ormarici/27",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI 500 ART-A5",
    ormSifra1: "35-800-A5",
    ormDimenzije1: "500x500x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 32,
    name: "ORMARIC LUX KONZOLNI",
    img: "./images/JPEG/131.jpg",
    img2: "./images/ormarici/13",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI LUX + LAVABO SLIM",
    ormSifra1: "35-920",
    ormDimenzije1: "550x340x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 33,
    name: "ORMARIC LUX KONZOLNI",
    img: "./images/JPEG/331.jpg",
    img2: "./images/ormarici/33",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName2: "ORMARIC KONZOLNI LUX + LAVABO SLIM",
    ormSifra2: "35-670",
    ormDimenzije2: "510x460x500",
    ormName3: "ORMARIC KONZOLNI LUX + LAVABO SLIM",
    ormSifra3: "35-671",
    ormDimenzije3: "610x460x500",
    ormName4: "ORMARIC KONZOLNI LUX + LAVABO SLIM",
    ormSifra4: "35-672",
    ormDimenzije4: "710x460x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 34,
    name: "ORMARIC LUX",
    img: "./images/JPEG/121.jpg",
    img2: "./images/ormarici/12",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC LUX + LAVABO SLIM",
    ormSifra1: "35-750",
    ormDimenzije1: "510x460x850",
    ormName2: "ORMARIC LUX + LAVABO SLIM",
    ormSifra2: "35-751",
    ormDimenzije2: "610x460x850",
    ormName3: "ORMARIC LUX + LAVABO SLIM",
    ormSifra3: "35-752",
    ormDimenzije3: "710x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 35,
    name: "ORMARIC LUX 810",
    img: "./images/JPEG/210.jpg",
    img2: "./images/ormarici/21",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC LUX + LAVABO SLIM",
    ormSifra1: "35-753",
    ormDimenzije1: "810x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 36,
    name: "ORMARIC LUX 910",
    img: "./images/JPEG/221.jpg",
    img2: "./images/ormarici/22",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC LUX + LAVABO SLIM",
    ormSifra1: "35-754",
    ormDimenzije1: "910x460x850",
    ormName2: "ORMARIC LUX + LAVABO SLIM",
    ormSifra2: "35-755",
    ormDimenzije2: "1010x460x850",
    // ormSifra3: "35-752",
    // ormDimenzije3: "710x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 37,
    name: "ORMARIC SLIM",
    img: "./images/JPEG/101.jpg",
    img2: "./images/ormarici/10",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC SLIM + LAVABO SLIM",
    ormSifra1: "35-200",
    ormDimenzije1: "410x460x850",
    ormName2: "ORMARIC SLIM + LAVABO SLIM",
    ormSifra2: "35-201",
    ormDimenzije2: "510x460x850",
    ormName3: "ORMARIC SLIM + LAVABO SLIM",
    ormSifra3: "35-202",
    ormDimenzije3: "610x460x850",
    ormName4: "ORMARIC SLIM + LAVABO SLIM",
    ormSifra4: "35-203",
    ormDimenzije4: "710x460x850",
    ogName: 0,
    vName: 0,
  },
  {
    id: 38,
    name: "ORMARIC WHITE KONZOLNI",
    img: "./images/JPEG/321.jpg",
    img2: "./images/ormarici/32",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI WHITE + LAVABO ALVIT",
    ormSifra1: "35-198",
    ormDimenzije1: "450x250x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 39,
    name: "ORMARIC KARAMEL KONZOLNI",
    img: "./images/JPEG/301.jpg",
    img2: "./images/ormarici/30",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    tehnicki: "./images/tehnicki/white 2.jpg",
    ormName: "ORMARIC KONZOLNI KARAMEL + LAVABO ALVIT",
    ormSifra1: "35-195",
    ormDimenzije1: "450x250x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 40,
    name: "ORMARIC LIGHT KONZOLNI",
    img: "./images/JPEG/311.jpg",
    img2: "./images/ormarici/31",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI LIGHT + LAVABO ALVIT",
    ormSifra1: "35-196",
    ormDimenzije1: "450x250x500",
    ogName: 0,
    vName: 0,
  },
  {
    id: 41,
    name: "ORMARIC FIT KONZOLNI",
    img: "./images/JPEG/61.jpg",
    img2: "./images/ormarici/6",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ormarici",
    ormName: "ORMARIC KONZOLNI FIT + LAVABO SLIM",
    ormSifra1: "35-350",
    ormDimenzije1: "500x400x500",
    ormName2: "ORMARIC KONZOLNI FIT + LAVABO SLIM",
    ormSifra2: "35-351",
    ormDimenzije2: "600x400x500",
    ormName3: "ORMARIC KONZOLNI FIT + LAVABO SLIM",
    ormSifra3: "35-352",
    ormDimenzije3: "700x400x500",
    ogName: 0,
    vName: 0,
  },
  // ****************** END ORMARICI *************************
  // ****************** OGLEDALA *****************************
  {
    id: 100,
    name: "OGLEDALO KARAMEL",
    img: "./images/ogledala/JPEG/121.jpg",
    img2: "./images/ogledala/1",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl karamel i light.jpg",
    ormName: "OGLEDALO KARAMEL",
    ormSifra1: "35-190",
    ormDimenzije1: "450x120x600",
    ogName: 0,
    vName: 0,
  },
  {
    id: 101,
    name: "OGLEDALO LIGHT",
    img: "./images/ogledala/JPEG/123.jpg",
    img2: "./images/ogledala/2",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl karamel i light.jpg",
    ormName: "OGLEDALO LIGHT",
    ormSifra1: "35-191",
    ormDimenzije1: "450x120x600",
    ogName: 0,
    vName: 0,
  },
  {
    id: 102,
    name: "OGLEDALO SLIM - LUX",
    img: "./images/ogledala/JPEG/127.jpg",
    img2: "./images/ogledala/3",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl lux 510 610 skica.jpg",
    ormName: "OGLEDALO SLIM - LUX",
    ormSifra1: "35-700",
    ormDimenzije1: "510x280x750",
    ormName2: "OGLEDALO SLIM - LUX",
    ormSifra2: "35-701",
    ormDimenzije2: "610x280x750",
    ogName: 0,
    vName: 0,
  },
  {
    id: 103,
    name: "OGLEDALO SLIM - LUX 800",
    img: "./images/ogledala/JPEG/125.jpg",
    img2: "./images/ogledala/4",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl lux 710 810 skica.jpg",
    ormName: "OGLEDALO SLIM - LUX",
    ormSifra1: "35-702",
    ormDimenzije1: "710x280x750",
    ormName2: "OGLEDALO SLIM - LUX",
    ormSifra2: "35-703",
    ormDimenzije2: "810x280x750",
    ogName: 0,
    vName: 0,
  },
  {
    id: 104,
    name: "OGLEDALO TEA 800",
    img: "./images/ogledala/JPEG/126.jpg",
    img2: "./images/ogledala/5",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl tea 810 skica.jpg",
    ormName: "OGLEDALO TEA 800",
    ormSifra1: "35-653",
    ormDimenzije1: "810x250x710",
    ormName2: "OGLEDALO TEA 900",
    ormSifra2: "35-654",
    ormDimenzije2: "910x280x750",
    ormName3: "OGLEDALO TEA 1010",
    ormSifra3: "35-655",
    ormDimenzije3: "1010x280x750",
    ogName: 0,
    vName: 0,
  },
  {
    id: 105,
    name: "OGLEDALO SLIM",
    img: "./images/ogledala/JPEG/124.jpg",
    img2: "./images/ogledala/6",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl slim 510 610 skica.jpg",
    ormName: "OGLEDALO SLIM",
    ormSifra1: "35-231",
    ormDimenzije1: "510x250x750",
    ormName2: "OGLEDALO SLIM",
    ormSifra2: "35-232",
    ormDimenzije2: "610x280x750",
    ogName: 0,
    vName: 0,
  },
  {
    id: 106,
    name: "OGLEDALO TEA",
    img: "./images/ogledala/JPEG/128.jpg",
    img2: "./images/ogledala/7",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl tea 510 610 710 skica.jpg",
    ormName: "OGLEDALO TEA",
    ormSifra1: "35-650",
    ormDimenzije1: "510x250x710",
    ormName2: "OGLEDALO TEA",
    ormSifra2: "35-651",
    ormDimenzije2: "610x250x710",
    ormName3: "OGLEDALO TEA",
    ormSifra3: "35-652",
    ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 107,
    name: "OGLEDALO TULIP - LUX",
    img: "./images/ogledala/JPEG/ogl tulip lux 550.jpg",
    img2: "./images/ogledala/8",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl tulip lux 550 skica.jpg",
    ormName: "OGLEDALO TULIP - LUX",
    ormSifra1: "35-650",
    ormDimenzije1: "550x230x700",
    // ormSifra2: "35-651",
    // ormDimenzije2: "610x250x710",
    // ormSifra3: "35-652",
    // ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 108,
    name: "OGLEDALO TULIP - LUX 800",
    img: "./images/ogledala/JPEG/orm tulip lux 650 800.jpg",
    img2: "./images/ogledala/9",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/orm tulip lux 650 800 skica.jpg",
    ormName: "OGLEDALO TULIP - LUX 650",
    ormSifra1: "35-182",
    ormDimenzije1: "650x230x700",
    ormName2: "OGLEDALO TULIP - LUX 800",
    ormSifra2: "35-183",
    ormDimenzije2: "800x250x710",
    // ormSifra3: "35-652",
    // ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 109,
    name: "OGLEDALO EKO",
    img: "./images/ogledala/JPEG/ogl eko 550 650.jpg",
    img2: "./images/ogledala/10",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl eko 550 650 skica.jpg",
    ormName: "OGLEDALO EKO",
    ormSifra1: "35-061",
    ormDimenzije1: "550x180x610",
    ormName2: "OGLEDALO EKO",
    ormSifra2: "35-062",
    ormDimenzije2: "650x180x610",
    // ormSifra3: "35-652",
    // ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 110,
    name: "OGLEDALO EKO 800",
    img: "./images/ogledala/JPEG/ogl eko 800 1000.jpg",
    img2: "./images/ogledala/11",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl eko 800 1000 skica.jpg",
    ormName: "OGLEDALO EKO 800",
    ormSifra1: "35-063",
    ormDimenzije1: "800x180x640",
    ormName2: "OGLEDALO EKO 1000",
    ormSifra2: "35-064",
    ormDimenzije2: "1000x180x640",
    // ormSifra3: "35-652",
    // ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 111,
    name: "OGLEDALO TULIP",
    img: "./images/ogledala/JPEG/ogl gornji deo tulip 550 650.jpg",
    img2: "./images/ogledala/12",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl 550 650 tulip skica.jpg",
    ormName: "OGLEDALO TULIP",
    ormSifra1: "35-030",
    ormDimenzije1: "550x180x1000",
    ormName2: "OGLEDALO TULIP",
    ormSifra2: "35-031",
    ormDimenzije2: "650x180x1000",
    // ormSifra3: "35-652",
    // ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 112,
    name: "OGLEDALO TULIP 800",
    img: "./images/ogledala/JPEG/ogl  eko 800 1000  d.jpg",
    img2: "./images/ogledala/13",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl  tulip 800 1000  d skica.jpg",
    ormName: "OGLEDALO TULIP 800",
    ormSifra1: "35-032",
    ormDimenzije1: "800x180x1000",
    ormName2: "OGLEDALO TULIP 1000",
    ormSifra2: "35-033",
    ormDimenzije2: "1000x180x1000",
    // ormSifra3: "35-652",
    // ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 113,
    name: "OGLEDALO TEA 1200",
    img: "./images/ogledala/JPEG/ogl tea 1200.jpg",
    img2: "./images/ogledala/14",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl tea 1200 skica.jpg",
    ormName: "OGLEDALO TEA 1200",
    ormSifra1: "35-656",
    ormDimenzije1: "1200x130x700",
    // ormSifra2: "35-033",
    // ormDimenzije2: "1000x180x1000",
    // ormSifra3: "35-652",
    // ormDimenzije3: "710x250x710",
    ogName: 0,
    vName: 0,
  },
  {
    id: 114,
    name: "OGLEDALO X-02",
    img: "./images/ogledala/JPEG/ogl x02.jpg",
    img2: "./images/ogledala/15",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl x02 skica.jpg",
    ormName: "OGLEDALO X-02",
    ormSifra1: "35-104",
    ormDimenzije1: "460x230x640",
    ogName: 0,
    vName: 0,
  },
  {
    id: 115,
    name: "OGLEDALO X-03",
    img: "./images/ogledala/JPEG/ogl x03.jpg",
    img2: "./images/ogledala/16",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl x03 skica.jpg",
    ormName: "OGLEDALO X-03",
    ormSifra1: "35-103",
    ormDimenzije1: "610x230x640",
    ogName: 0,
    vName: 0,
  },
  {
    id: 116,
    name: "OGLEDALO EKO-03",
    img: "./images/ogledala/JPEG/ogl eko 03.jpg",
    img2: "./images/ogledala/17",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl eko 03 skica.jpg",
    ormName: "OGLEDALO EKO-03",
    ormSifra1: "35-105",
    ormDimenzije1: "610x130x640",
    ogName: 0,
    vName: 0,
  },
  {
    id: 118,
    name: "OGLEDALO SLIM LINE",
    img: "./images/ogledala/JPEG/SLIM.jpg",
    img2: "./images/ogledala/18",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl line.jpg",
    ormName: "OGLEDALO SLIM LINE",
    ormSifra1: "35-893",
    ormDimenzije1: "610x180x1000",
    ormName2: "OGLEDALO SLIM LINE",
    ormSifra2: "35-894",
    ormDimenzije2: "710x180x1000",
    ogName: 0,
    vName: 0,
  },

  {
    id: 120,
    name: "OGLEDALO KARAMEL",
    img: "./images/ogledala/JPEG/KARAMEL.jpg",
    img2: "./images/ogledala/19",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl karamel.jpg",
    ormName: "OGLEDALO KARAMEL",
    ormSifra1: "35-855",
    ormDimenzije1: "510x130x700",
    ormName2: "OGLEDALO KARAMEL",
    ormSifra2: "35-856",
    ormDimenzije2: "610x130x700",
    ormName3: "OGLEDALO KARAMEL",
    ormSifra3: "35-856",
    ormDimenzije3: "710x130x700",
    ogName: 0,
    vName: 0,
  },
  {
    id: 121,
    name: "OGLEDALO SONOMA",
    img: "./images/ogledala/JPEG/SONOMA.jpg",
    img2: "./images/ogledala/20",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogl sonoma.jpg",
    ormName: "OGLEDALO SONOMA",
    ormSifra1: "35-855",
    ormDimenzije1: "450x120x600",
    ogName: 0,
    vName: 0,
  },
  {
    id: 119,
    name: "OGLEDALO LIGHT",
    img: "./images/ogledala/JPEG/LIGHT.jpg",
    img2: "./images/ogledala/21",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "ogledala",
    tehnicki: "./images/tehnicki/ogledalo light.jpg",
    ormName: "OGLEDALO LIGHT",
    ormSifra1: "35-875",
    ormDimenzije1: "510x130x700",
    ormName2: "OGLEDALO LIGHT",
    ormSifra2: "35-876",
    ormDimenzije2: "610x130x700",
    ormName3: "OGLEDALO LIGHT",
    ormSifra3: "35-877",
    ormDimenzije3: "710x130x700",
    ogName: 0,
    vName: 0,
  },
  // ****************** END OGLEDALA *************************
  // ****************** VERTIKALE ****************************
  {
    id: 200,
    name: "VERTIKALA MINI BEZ KORPE",
    img: "./images/vertikale/JPEG/mini-bez-korpe.jpg",
    img2: "./images/vertikale/1",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/svertikala mini bez korpe skica.jpg",
    ormName: "VERTIKALA MINI BEZ KORPE",
    ormSifra1: "35-300",
    ormDimenzije1: "350x310x900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 201,
    name: "VERTIKALA MINI SA KORPOM",
    img: "./images/vertikale/JPEG/mini-sa-korpom.jpg",
    img2: "./images/vertikale/2",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vertikala mini sa korpom skica.jpg",
    ormName: "VERTIKALA MINI SA KORPOM",
    ormSifra1: "35-301",
    ormDimenzije1: "350x310x900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 202,
    name: "VERTIKALA 350 SA KORPOM",
    img: "./images/vertikale/JPEG/vertikala(6).jpg",
    img2: "./images/vertikale/3",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vertikala sa korpom skica.jpg",
    ormName: "VERTIKALA 350 SA KORPOM",
    ormSifra1: "35-306",
    ormDimenzije1: "350x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 203,
    name: "VERTIKALA 350 BEZ KORPE",
    img: "./images/vertikale/JPEG/vertikala(6).jpg",
    img2: "./images/vertikale/4",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vertikala bez korpe skica.jpg",
    ormName: "VERTIKALA 350 BEZ KORPE",
    ormSifra1: "35-305",
    ormDimenzije1: "350x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 212,
    name: "VERTIKALA 500 BEZ KORPE",
    img: "./images/vertikale/JPEG/vertikala-velika(2).jpg",
    img2: "./images/vertikale/5",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vertikala bez korpe skica.jpg",
    ormName: "VERTIKALA 500 BEZ KORPE",
    ormSifra1: "35-308",
    ormDimenzije1: "500x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 213,
    name: "VERTIKALA 500 SA KORPOM",
    img: "./images/vertikale/JPEG/vertikala-velika(22).jpg",
    img2: "./images/vertikale/6",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vertikala sa korpom skica.jpg",
    ormName: "VERTIKALA 500 SA KORPOM",
    ormSifra1: "35-307",
    ormDimenzije1: "500x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 204,
    name: "VERTIKALA LUX 500 BEZ KORPE",
    img: "./images/vertikale/JPEG/bez-korpe-lux-500.jpg",
    img2: "./images/vertikale/7",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vert bez korpe lux skica.jpg",
    ormName: "VERTIKALA LUX 500 BEZ KORPE",
    ormSifra1: "35-310",
    ormDimenzije1: "500x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 205,
    name: "VERTIKALA 500 LUX SA KORPOM",
    img: "./images/vertikale/JPEG/sa-korpom-lux-500.jpg",
    img2: "./images/vertikale/8",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vert sa korpom lux skica.jpg",
    ormName: "VERTIKALA 500 LUX SA KORPOM",
    ormSifra1: "35-309",
    ormDimenzije1: "500x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 206,
    name: "VERTIKALA 350 LUX BEZ KORPE",
    img: "./images/vertikale/JPEG/sa-korpom-lux-350.jpg",
    img2: "./images/vertikale/9",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vert bez korpe lux skica.jpg",
    ormName: "VERTIKALA 350 LUX BEZ KORPE",
    ormSifra1: "35-312",
    ormDimenzije1: "350x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 207,
    name: "VERTIKALA 350 LUX SA KORPOM",
    img: "./images/vertikale/JPEG/sa-korpom-lux-350.jpg",
    img2: "./images/vertikale/10",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vert sa korpom lux skica.jpg",
    ormName: "VERTIKALA 350 LUX SA KORPOM",
    ormSifra1: "35-313",
    ormDimenzije1: "350x300x1900",
    ogName: 0,
    vName: 0,
  },
  {
    id: 208,
    name: "VERTIKALA SONOMA SA KORPOM KONZOLNA",
    img: "./images/vertikale/JPEG/IMG_0665-VERTIKALA-SONOMA.jpg",
    img2: "./images/vertikale/11",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/ver sonoma.jpg",
    ormName: "VERTIKALA SONOMA SA KORPOM KONZOLNA",
    ormSifra1: "35-388",
    ormDimenzije1: "350x300x1400",
    ogName: 0,
    vName: 0,
  },
  {
    id: 209,
    name: "VERTIKALA KARAMEL SA KORPOM KONZOLNA",
    img: "./images/vertikale/JPEG/IMG_0588--VERTIKALA-KARAMEL-KONZOLA.jpg",
    img2: "./images/vertikale/12",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/ver karamel.jpg",
    ormName: "VERTIKALA KARAMEL SA KORPOM KONZOLNA",
    ormSifra1: "35-860",
    ormDimenzije1: "350x300x1520",
    ogName: 0,
    vName: 0,
  },
  {
    id: 210,
    name: "VERTIKALA LIGHT SA KORPOM KONZOLNA",
    img: "./images/vertikale/JPEG/IMG_0657-VERTIKALA-LIGHT.jpg",
    img2: "./images/vertikale/13",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/vertikala light.jpg",
    ormName: "VERTIKALA LIGHT SA KORPOM KONZOLNA",
    ormSifra1: "35-880",
    ormDimenzije1: "350x300x1520",
    ogName: 0,
    vName: 0,
  },
  {
    id: 211,
    name: "VERTIKALA LINE SA KORPOM",
    img: "./images/vertikale/JPEG/IMG_0637-VERTIKALA-LINE(2).jpg",
    img2: "./images/vertikale/14",
    b1: "./images/bedz/16.png",
    b2: "./images/bedz/18.png",
    // b3: "./images/bedz/led.png",
    b4: "./images/bedz/okov.png",
    b5: "./images/bedz/gloss.png",
    about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
    group: "vertikale",
    tehnicki: "./images/tehnicki/ver line.jpg",
    ormName: "VERTIKALA LINE SA KORPOM",
    ormSifra1: "35-896",
    ormDimenzije1: "350x300x900",
    ogName: 0,
    vName: 0,

  },
  // ****************** END VERTIKALE *************************
]
export const detailProduct = {
  id: 1111,
  name: "detaljji",
  img: "./images/10.jpg",
  about: "Proizvod je izrađen od medijapana debljine 16mm i univera debljine 18mm, bojen poliuretanskim bojama.",
  group: "Lorem "
};
